<template>
  <v-container class="grey lighten-5">
    <v-row no-gutters>
      <v-col
        cols="12"
        sm="6"
        class="text-center"
      >
        <v-card
          class="pa-2"
          outlined
          tile
        >
        <v-card-title v-show="verForm" primary-title class="blue--text" style="word-break: break-word;">
          <p class="display-1">GENERADOR DE CRACHÁ</p>
          <p class="headline">Conferencia Internacional Febrero 2021</p>
        </v-card-title>
          <v-card-text>
            <v-form
              ref="form"
              v-show="verForm"
              v-model="valid"
            >
              <v-text-field
                v-model="nombre"
                label="Nombre Completo"
                :rules="nombreReglas"
                :counter="23"
                required
              ></v-text-field>
              <v-text-field
                v-model="localidad"
                label="Localidad"
                :rules="localidadReglas"
                :counter="23"
                required
              ></v-text-field>
              <v-btn
                color="success"
                class="mr-4"
                @click="generarCracha()"
              >
                GENERAR CRACHÁ
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
        <v-card
          class="pa-2"
          outlined
          tile
        >
        <v-card-title primary-title class="justify-center">
          <p class="blue--text headline" style="word-break: break-word; margin-bottom: -15px;">Iglesia en Coquimbo, del Ministerio Vida Para Todos</p>
        </v-card-title>
        <v-row no-gutters>
          <v-col
            cols="6"
            sm="6"
          >
          <a href="https://iglesiaencoquimbo.cl/" target="_blank"><v-img src="/img/logonew.png" width="150" class="ml-auto mr-auto"></v-img></a>
          </v-col>
          <v-col
            cols="6"
            sm="6"
          >
          <a href="http://institutovidaparatodos.org.br/" target="_blank"><v-img src="/img/logoministerio.png" width="150" class="ml-auto mr-auto"></v-img></a>
          </v-col>
        </v-row>
        </v-card>
        <v-card
          class="pa-2"
          outlined
          tile
        >
        <v-row no-gutters>
          <v-col
            cols="6"
            sm="6"
          >
          <a href="https://www.facebook.com/bookafe.coquimbo" target="_blank"><v-img src="/img/face.png" width="50" class="ml-auto"></v-img></a>
          </v-col>
          <v-col
            cols="6"
            sm="6"
          >
          <a href="https://www.youtube.com/channel/UCG5l9dM4vvFPrUyv0uUBpGg/videos" target="_blank"><v-img src="/img/youtube.png" width="50" class="mr-auto"></v-img></a>
          </v-col>
        </v-row>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        class="text-center"
      >
        <v-card
          class="pa-2"
          outlined
          tile
        >
          <v-img :src="crachaImg" width="480.5" class="ml-auto mr-auto"></v-img>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
      <v-card class="text-center">
        <v-card-title primary-title>
          <p class="blue--text" style="word-break: break-word;">GENERANDO SU CRACHÁ... POR FAVOR, ESPERE :)</p>
          <v-progress-circular
            :rotate="-90"
            :size="100"
            :width="15"
            :value="value"
            color="primary"
            class="ml-auto mr-auto"
          >
            {{ value }}
          </v-progress-circular>
        </v-card-title>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogFinal"
      persistent
      max-width="350"
    >
      <v-card class="text-center">
        <v-card-title primary-title>
          <p class="blue--text" style="word-break: break-word;">
            CRACHÁ GENERADA. SIGA SIEMPRE DISFRUTANDO DEL SEÑOR JESÚS E INVOCANDO SU NOMBRE: ¡OH SEÑOR JESÚS!
          </p>
        </v-card-title>
        <v-card-actions class="text-center">
          <a class="mr-4 v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default success" :href="crachaImg" download>DESCARGAR CRACHÁ</a>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'
export default {
  data: () => ({
    valid: false,
    nombre: '',
    localidad: '',
    verForm: true,
    crachaImg: '/img/cracha.jpg',
    dialog: false,
    value: 0,
    interval: {},
    correcto: 'no',
    api: 'https://apicracha.iglesiaencoquimbo.cl',
    dialogFinal: false,
    nombreReglas: [
      v => !!v || 'El nombre completo es requerido.',
      v => v.length <= 23 || 'El nombre debe tener como máximo 23 caracteres.'
    ],
    localidadReglas: [
      v => !!v || 'El localidad es requerido.',
      v => v.length <= 23 || 'La localidad debe tener como máximo 23 caracteres.'
    ]
  }),

  methods: {
    async llamadaBack () {
      try {
        const post = {
          Nombre: this.nombre,
          Localidad: this.localidad
        }
        const response = await axios.post(this.api + '/api/cracha/generarcracha', post)
        this.crachaImg = this.api + '/crachas/' + response.data
        this.correcto = 'si'
      } catch (error) {
        alert(error)
        this.dialog = false
      }
    },
    generarCracha () {
      if (this.valid === true) {
        this.value = 0
        this.dialog = true
        this.llamadaBack()
      } else {
        alert('Corrobore las reglas de ingreso de nombre completo y localidad (máximo 23 caracteres cada uno) (que las casillas contengan texto)')
      }
    },
    exito () {
      this.verForm = false
      this.dialogFinal = true
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  mounted () {
    this.interval = setInterval(() => {
      if (this.value === 100) {
        this.dialog = false
        if (this.correcto === 'si') {
          this.exito()
        }
        return (this.value = 0)
      }
      this.value += 50
    }, 1000)
  }
}
</script>
